<template>

  <dash-page-new
      v-if="!isChildRoot"
      :title="$t('Template')"
      :side-navigation="avalonAdminTemplateNavigationItems"
      :navigation-return-route="localeLink('avalon_editor/templates')"
  >
  </dash-page-new>

</template>

<script>
import {mapActions} from "vuex";
// import avalonPreview from "@/components/AvalonEditor/Editor/avalonPreview";

export default {
  name: "adminTemplateEdit",
  props : {
    templateId : {
      type : String
    }
  },
  data() {
    return {
      entity : {}
    }
  },
  computed : {
    isChildRoot() {
      let result = false

      let array = this.avalonAdminTemplateNavigationItems.map(el=> el.path)

      array.forEach( child => {
        if ( this.$route.path.includes(child)) {
          result = true
        }
      })

      return result
    }
  },
  methods : {
    ...mapActions('avalon' , [
        'GET_ADMIN_TEMPLATE'
    ]),
  },
  async mounted() {

    let result = await this.GET_ADMIN_TEMPLATE(this.templateId)

    if ( !result ) {
      return
    }

    this.$store.state.avalon.config = result.config

    if ( !result.header ) {
      this.$store.state.avalon.header = {
        config : {}
      }
    }

  }
}
</script>
<style scoped>

</style>